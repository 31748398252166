var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.isReady || _vm.isGetRequestPending)?_c('section',{staticClass:"shapes"},[_c('p-loading')],1):_c('section',{staticClass:"shapes"},[_vm._m(0),_c('p-list',{attrs:{"name":"shapes","data":_vm.collection,"total":_vm.total,"metadata":{
      thumbnailUrl: { label: 'Thumbnail', width: '100px' },
      name: { label: 'Name', width: 'minmax(0, 3fr)' },
      description: { label: 'Description', width: 'minmax(0, 3fr)' },
      tags: { label: 'Tags', width: 'minmax(100px, 2fr)' },
      modifiedAt: { label: 'Last modification', width: '175px' }
    }},on:{"edit":_vm.edit,"hook:mounted":_vm.getIntersectionOptions},scopedSlots:_vm._u([{key:"thumbnailUrl",fn:function(ref){
    var value = ref.value;
return [_c('div',{staticClass:"thumbnail-image-wrapper"},[(_vm.intersectionOptions)?_c('v-lazy-image',{staticClass:"thumbnail-image",attrs:{"src":value,"intersection-options":_vm.intersectionOptions}}):_vm._e()],1)]}},{key:"__actions",fn:function(ref){
    var item = ref.item;
return [_c('p-button',{attrs:{"variant":"text","title":"Edit this shape"},on:{"click":function($event){return _vm.edit(item.id)}}},[_vm._v(" ✎")])]}}])})],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('header',[_c('h1',[_vm._v("Shapes")]),_c('section',{staticClass:"action-wrapper"})])}]

export { render, staticRenderFns }