<template>
  <section v-if="!isReady || isGetRequestPending" class="shapes">
    <p-loading />
  </section>
  <section v-else class="shapes">
    <header>
      <h1>Shapes</h1>
      <section class="action-wrapper"></section>
    </header>

    <p-list
      name="shapes"
      :data="collection"
      :total="total"
      :metadata="{
        thumbnailUrl: { label: 'Thumbnail', width: '100px' },
        name: { label: 'Name', width: 'minmax(0, 3fr)' },
        description: { label: 'Description', width: 'minmax(0, 3fr)' },
        tags: { label: 'Tags', width: 'minmax(100px, 2fr)' },
        modifiedAt: { label: 'Last modification', width: '175px' }
      }"
      @edit="edit"
      @hook:mounted="getIntersectionOptions"
    >
      <template #thumbnailUrl="{ value }">
        <div class="thumbnail-image-wrapper">
          <v-lazy-image v-if="intersectionOptions" :src="value" class="thumbnail-image" :intersection-options="intersectionOptions" />
        </div>
      </template>
      <template #__actions="{ item }">
        <p-button variant="text" title="Edit this shape" @click="edit(item.id)"> &#9998;</p-button>
      </template>
    </p-list>
  </section>
</template>

<script>
import { mapState } from 'vuex';

import Button from './../common/Button';
import Loading from './../common/Loading';
import List from './../common/List';

export default {
  components: {
    'p-button': Button,
    'p-loading': Loading,
    'p-list': List
  },
  data() {
    return {
      isReady: false,

      intersectionOptions: null
    };
  },
  computed: {
    ...mapState({
      collection: s => s.shapes.collection,
      total: s => s.shapes.total,
      isGetRequestPending: s => s.shapes.isGetRequestPending
    }),
    sorted() {
      return this.collection;
    }
  },
  async created() {
    await this.$store.dispatch('shapes/getCollection');
    this.isReady = true;
  },
  methods: {
    async edit(id) {
      this.$router.push({ path: `/shapes/${id}/edit` });
    },
    getIntersectionOptions() {
      const val = {
        root: document.getElementById('shapes-list'),
        rootMargin: '100px',
        threshold: 0
      };

      this.intersectionOptions = val;
    }
  }
};
</script>

<style scoped lang="scss">
.shapes {
  display: grid;
  padding: 0 0.5rem 0 1rem;
  grid-template-columns: 1fr;
  grid-template-rows: max-content minmax(0, 1fr);
  grid-gap: 0.5rem;
  width: 100%;
  height: 100%;

  > header {
    padding: 0.5rem 0.5rem 0;
    display: flex;
    justify-content: space-between;

    box-sizing: border-box;
    h1 {
      color: var(--theme-on-surface);
      font-size: 1.5rem;
      font-weight: 700;
      margin-right: 0.25rem;
    }

    .action-wrapper {
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }
  }

  .thumbnail-image-wrapper {
    width: 100%;
    height: 50px;
    display: flex;
  }
  .thumbnail-image {
    max-width: 100%;
    max-height: 100%;
    display: block;
    margin: auto;
    box-sizing: border-box;
  }
}
</style>
